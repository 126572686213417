<!-- Envio Email Facturas Emitidas -->

<template>
  <div class="avisosFras_email">      
    <!-- Cabecera -->
    <div class="cabecera">
      <base_Header
        v-if="Entorno.header.header"       
        :Entorno="Entorno.header"
        @onEvent="$emit('onEvent', { accion:'cerrar' })">
      </base_Header>
    </div> 

    <div class="columna">
      <v-sheet :elevation="4">
     
        <!-- Botoneras -->
        <div class="conflex pl-2 pt-2">
          <v-btn 
            v-bind="$cfg.btra_cfg.standard"
            @click="email()">
              <v-icon>{{'mdi-email-send-outline'}}</v-icon>
          </v-btn>
        </div>

        <!-- Controles del Mto -->
        <div class="ctrls conflex contenedor max-width:1240px"> 
          <div class="columna" style="width:550px">              
            <v-text-field
                v-bind="$input"
                v-model="schema.ctrls.email.value"
                :label="schema.ctrls.email.label">          
            </v-text-field>

            <!-- <v-select
              v-bind="$select"
              v-model="schema.ctrls.adjuntos.value"
              :label="schema.ctrls.adjuntos.label"            
              :items="itemsAdjuntos" 
              item-value="id"
              item-text="name"          
              multiple>
            
                <template v-slot:selection="{ index }">              
                  <span  
                    v-if="index=== 0"              
                    class="grey--text caption">                
                      ({{ schema.ctrls.adjuntos.value.length }} documentos adjuntos)
                  </span>
                </template>
            </v-select> -->
            
            <v-textarea
                v-bind="$textarea"
                v-model="schema.ctrls.txt.value"
                :label="schema.ctrls.txt.label"
                rows="3"
                no-resize>                                           
            </v-textarea>

          </div>
        </div>          
      </v-sheet> 
    </div>   
  </div>
</template>



<script>

  import { mixinMto } from "@/mixins/mixinMto.js";  
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
    
  export default {
    mixins: [mixinMto],
    components: { base_Header },
    props: {
      padre: { type:String, default: '' }, 
      accion: { type:[Number, String], default: ''},
      accionRow: { type:Object, default: ()=> {}}, 
      auxRecord: { type:Object, default: ()=> {}}, 
      show: { type: [String, Boolean], default: "" },    
      componenteTipo: { type:String, default:'M' },
      Entorno: { type:Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },

    data() {
      return {
        schema: {
          ctrls: {
            email: { f:'email', label:'Correo Electronico', value:""},
            adjuntos: { f:'adjuntos', label:'Documentos adjuntos', value:""},
            txt: { f:'txt', label:'Observaciones', value:""},
          }
        },
        
        api:'',
        stName:'stMavisosFras_email',
      
        dialog:false,
        //itemsAdjuntos: []
      };
    },

    
   

    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // guardo del store en variables locales
        //this.schema = this.$store.state[this.stName].schema;        
        
        // configuro Entorno 
        this.Entorno.header.titulo = "Avisos - Documentacón - Email";         
      },


      //
      ini_schemaCtrls() {
        this.schema.ctrls.email.value= this.auxRecord.email;
        this.schema.ctrls.txt.value="Para completar el expediente quedamos a la espera de ...";
      },


      //
      ini_component() {},


      // envio email
      email() { 
        if (!this.schema.ctrls.txt.value) return this.$root.$alert.open('Debe introducir Texto de Respuesta', 'info');
        if (!this.schema.ctrls.email.value) return this.$root.$alert.open('Debe introducir Correo Electrónico', 'info');
              
        let msg='<b><u>VISTO / OK Documento Recibido</u></b><br> Aceptamos / VISTO la Recepcion ?<br>' + 
            '<br>Se envia Correo Electronico a <b>' + this.schema.ctrls.email.value + '</b><br>';

        this.$root.$alert.open(msg , 'confirm', null, ["ACEPTAR", "CANCELAR"])
        .then(r=> { if (r== 0) this.email_continue() }
        );
      },

    
      async email_continue() {
        let args = { tipo:'fnc', accion: 'expediente', fn_args: { 
          accion: 'avisos',
          fnt: 'set',
          tip: 2,
          env: 1,
          id:  this.auxRecord.id,
          txt: this.schema.ctrls.txt.value,
          ema: this.schema.ctrls.email.value,       

        }}
     
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });    
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
        
        // limpio selected
        this.$emit('onEvent', { accion:'cerrar' })

        // actualizo records en el store
        this.$store.commit(this.padre + '/update_records', apiResult.r[0]);
        this.$store.commit(this.padre + '/data2State', { prop:'recordsDetalle', value: apiResult.r }); 
      },

    }

  }
</script>
